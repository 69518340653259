import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { type } from '@testing-library/user-event/dist/type';

export const FikContext = createContext();

export const FikContextProvider = ({ children }) => {

  const navigate = useNavigate()
  const [fontSize, setFontSize] = useState('16px');
  const [constraste, setContraste] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik] = useState(false);
  const [showMessage, setShowMessage] = useState(false);



  const alterarFonteSize = (val) => {
    if (parseInt(fontSize) < 22 || parseInt(fontSize) > 16) {
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
    if (parseInt(fontSize) < 16) {
      setFontSize('16');
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
    if (parseInt(fontSize) > 22) {
      setFontSize('22');
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
  }

  const alterarContraste = (val) => {
    setContraste(val);
  }

  const abrePagina = (page) => {
    navigate(page);
  }


  const handleClick = () => {
    const element = document.querySelector('#send-message');
    if (element) {
      const isCurrentlyVisible = getComputedStyle(element).display !== 'none';

      // Sincroniza o estado com o DOM
      if (isCurrentlyVisible) {
        element.style.display = 'none';
        setShowMessage(false);
      } else {
        element.style.display = 'block';
        setShowMessage(true);
      }
    }
  };




  return (
    <FikContext.Provider value={{ fontSize, setFontSize, constraste, setContraste, alterarFonteSize, alterarContraste, abrePagina, visible, setVisible, handleClick, visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik }}>
      {children}
    </FikContext.Provider>
  );
};

export default FikContext;
